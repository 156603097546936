
import { Options, Vue } from 'vue-class-component'
import Patient from '@/models/Patient'
import YesNoCurrHistRadio from '@/components/YesNoCurrHistRadio.vue'
import CurrHistTextarea from '@/components/CurrHistTextarea.vue'
import SimpleQuestion from '@/components/SimpleQuestion.vue'
import { mapActions, mapGetters } from 'vuex'

@Options({
  components: {
    YesNoCurrHistRadio,
    CurrHistTextarea,
    SimpleQuestion
  },
  methods: mapActions([
    'updateDrugAnamnesis',
    'downloadPharmacologicalAnamnesis'
  ]),
  computed: mapGetters(['patient', 'drug_anamnesis'])
})
export default class PharmacologicalAnamnesis extends Vue {
  patient!: Patient
  // eslint-disable-next-line camelcase
  drug_anamnesis!: Patient['drug_anamnesis']

  // Actions
  updateDrugAnamnesis!: (payload: {
    id: number
    drugAnamnesis: Patient['drug_anamnesis']
  }) => Promise<void>

  downloadPharmacologicalAnamnesis!: () => Promise<void>

  created (): void {
    this.downloadPharmacologicalAnamnesis()
  }

  submit (): void {
    this.updateDrugAnamnesis({
      id: this.patient.id,
      drugAnamnesis: this.drug_anamnesis
    })
      .then(() => {
        this.$router.push({
          name: this.$route.meta.next,
          params: { id: this.patient.id }
        })
      })
      .catch(() => {
        this.$router.push({ name: 'Logout' })
      })
  }
}
