import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center bg-white rounded-lg p-8 border-2 border-gray-300" }
const _hoisted_2 = { class: "space-y-8 divide-y divide-gray-200 sm:space-y-5" }
const _hoisted_3 = { class: "text-lg leading-6 font-medium text-gray-900" }
const _hoisted_4 = { class: "mt-1 max-w-2xl text-sm text-gray-500" }
const _hoisted_5 = { class: "flex flex-col gap-4 sm:gap-0" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "pt-5" }
const _hoisted_11 = { class: "flex justify-end" }
const _hoisted_12 = {
  type: "submit",
  class: "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleQuestion = _resolveComponent("SimpleQuestion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "space-y-8 divide-y divide-gray-200",
      onSubmit: _cache[43] || (_cache[43] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('PharmacologicalAnamnesis.Title')), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('PharmacologicalAnamnesis.PersonalPatientInfo')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.ever_mph'),
              value: _ctx.drug_anamnesis.ever_mph,
              key: _ctx.drug_anamnesis.ever_mph,
              onChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.drug_anamnesis.ever_mph = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.ever_amf'),
              value: _ctx.drug_anamnesis.ever_amf,
              key: _ctx.drug_anamnesis.ever_amf,
              onChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.drug_anamnesis.ever_amf = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.ever_atx'),
              value: _ctx.drug_anamnesis.ever_atx,
              key: _ctx.drug_anamnesis.ever_atx,
              onChanged: _cache[2] || (_cache[2] = ($event: any) => (_ctx.drug_anamnesis.ever_atx = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.ever_alfa'),
              value: _ctx.drug_anamnesis.ever_alfa,
              key: _ctx.drug_anamnesis.ever_alfa,
              onChanged: _cache[3] || (_cache[3] = ($event: any) => (_ctx.drug_anamnesis.ever_alfa = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.current_any_drug'),
              value: _ctx.drug_anamnesis.current_any_drug,
              key: _ctx.drug_anamnesis.current_any_drug,
              onChanged: _cache[4] || (_cache[4] = ($event: any) => (_ctx.drug_anamnesis.current_any_drug = $event))
            }, null, 8, ["text", "value"])),
            _createVNode(_Transition, {
              "enter-active-class": "animate__animated animate__fadeIn",
              "leave-active-class": "animate__animated animate__fadeOut"
            }, {
              default: _withCtx(() => [
                (this.drug_anamnesis.current_any_drug)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_openBlock(), _createBlock(_component_SimpleQuestion, {
                        text: _ctx.$t('PharmacologicalAnamnesis.current_cns_drugs'),
                        value: _ctx.drug_anamnesis.current_cns_drugs,
                        key: _ctx.drug_anamnesis.current_cns_drugs,
                        onChanged: _cache[5] || (_cache[5] = ($event: any) => (_ctx.drug_anamnesis.current_cns_drugs = $event)),
                        subQuestionLvl: 1
                      }, null, 8, ["text", "value"])),
                      _createVNode(_Transition, {
                        "enter-active-class": "animate__animated animate__fadeIn",
                        "leave-active-class": "animate__animated animate__fadeOut"
                      }, {
                        default: _withCtx(() => [
                          (this.drug_anamnesis.current_cns_drugs)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                (_openBlock(), _createBlock(_component_SimpleQuestion, {
                                  text: 
                        _ctx.$t('PharmacologicalAnamnesis.current_psychotropics')
                      ,
                                  value: _ctx.drug_anamnesis.current_psychotropics,
                                  key: _ctx.drug_anamnesis.current_psychotropics,
                                  onChanged: _cache[6] || (_cache[6] = ($event: any) => (_ctx.drug_anamnesis.current_psychotropics = $event)),
                                  subQuestionLvl: 2
                                }, null, 8, ["text", "value"])),
                                _createVNode(_Transition, {
                                  "enter-active-class": "animate__animated animate__fadeIn",
                                  "leave-active-class": "animate__animated animate__fadeOut"
                                }, {
                                  default: _withCtx(() => [
                                    (this.drug_anamnesis.current_psychotropics)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                          (_openBlock(), _createBlock(_component_SimpleQuestion, {
                                            text: _ctx.$t('PharmacologicalAnamnesis.current_tt_adhd'),
                                            value: _ctx.drug_anamnesis.current_tt_adhd,
                                            key: _ctx.drug_anamnesis.current_tt_adhd,
                                            onChanged: _cache[7] || (_cache[7] = ($event: any) => (_ctx.drug_anamnesis.current_tt_adhd = $event)),
                                            subQuestionLvl: 3
                                          }, null, 8, ["text", "value"])),
                                          _createVNode(_Transition, {
                                            "enter-active-class": "animate__animated animate__fadeIn",
                                            "leave-active-class": "animate__animated animate__fadeOut"
                                          }, {
                                            default: _withCtx(() => [
                                              (this.drug_anamnesis.current_tt_adhd)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                                    (_openBlock(), _createBlock(_component_SimpleQuestion, {
                                                      text: _ctx.$t('PharmacologicalAnamnesis.current_mph'),
                                                      value: _ctx.drug_anamnesis.current_mph,
                                                      key: _ctx.drug_anamnesis.current_mph,
                                                      onChanged: _cache[8] || (_cache[8] = ($event: any) => (_ctx.drug_anamnesis.current_mph = $event)),
                                                      subQuestionLvl: 4
                                                    }, null, 8, ["text", "value"])),
                                                    (_openBlock(), _createBlock(_component_SimpleQuestion, {
                                                      text: _ctx.$t('PharmacologicalAnamnesis.current_amf'),
                                                      value: _ctx.drug_anamnesis.current_amf,
                                                      key: _ctx.drug_anamnesis.current_amf,
                                                      onChanged: _cache[9] || (_cache[9] = ($event: any) => (_ctx.drug_anamnesis.current_amf = $event)),
                                                      subQuestionLvl: 4
                                                    }, null, 8, ["text", "value"])),
                                                    (_openBlock(), _createBlock(_component_SimpleQuestion, {
                                                      text: _ctx.$t('PharmacologicalAnamnesis.current_atx'),
                                                      value: _ctx.drug_anamnesis.current_atx,
                                                      key: _ctx.drug_anamnesis.current_atx,
                                                      onChanged: _cache[10] || (_cache[10] = ($event: any) => (_ctx.drug_anamnesis.current_atx = $event)),
                                                      subQuestionLvl: 4
                                                    }, null, 8, ["text", "value"])),
                                                    (_openBlock(), _createBlock(_component_SimpleQuestion, {
                                                      text: 
                                _ctx.$t('PharmacologicalAnamnesis.current_alfa')
                              ,
                                                      value: _ctx.drug_anamnesis.current_alfa,
                                                      key: _ctx.drug_anamnesis.current_alfa,
                                                      onChanged: _cache[11] || (_cache[11] = ($event: any) => (_ctx.drug_anamnesis.current_alfa = $event)),
                                                      subQuestionLvl: 4
                                                    }, null, 8, ["text", "value"])),
                                                    (_openBlock(), _createBlock(_component_SimpleQuestion, {
                                                      text: 
                                _ctx.$t(
                                  'PharmacologicalAnamnesis.current_other_drug_adhd'
                                )
                              ,
                                                      value: _ctx.drug_anamnesis.current_other_drug_adhd,
                                                      key: _ctx.drug_anamnesis.current_other_drug_adhd,
                                                      onChanged: _cache[12] || (_cache[12] = ($event: any) => (
                                _ctx.drug_anamnesis.current_other_drug_adhd = $event
                              )),
                                                      subQuestionLvl: 4
                                                    }, null, 8, ["text", "value"]))
                                                  ]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 1
                                          })
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                })
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.stimulant_responders'),
              value: _ctx.drug_anamnesis.stimulant_responders,
              key: _ctx.drug_anamnesis.stimulant_responders,
              onChanged: _cache[13] || (_cache[13] = ($event: any) => (_ctx.drug_anamnesis.stimulant_responders = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.failure_mph'),
              value: _ctx.drug_anamnesis.failure_mph,
              key: _ctx.drug_anamnesis.failure_mph,
              onChanged: _cache[14] || (_cache[14] = ($event: any) => (_ctx.drug_anamnesis.failure_mph = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.failure_amf'),
              value: _ctx.drug_anamnesis.failure_amf,
              key: _ctx.drug_anamnesis.failure_amf,
              onChanged: _cache[15] || (_cache[15] = ($event: any) => (_ctx.drug_anamnesis.failure_amf = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.failure_atx'),
              value: _ctx.drug_anamnesis.failure_atx,
              key: _ctx.drug_anamnesis.failure_atx,
              onChanged: _cache[16] || (_cache[16] = ($event: any) => (_ctx.drug_anamnesis.failure_atx = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.failure_alfa'),
              value: _ctx.drug_anamnesis.failure_alfa,
              key: _ctx.drug_anamnesis.failure_alfa,
              onChanged: _cache[17] || (_cache[17] = ($event: any) => (_ctx.drug_anamnesis.failure_alfa = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.failure_other_drug_adhd'),
              value: _ctx.drug_anamnesis.failure_other_drug_adhd,
              key: _ctx.drug_anamnesis.failure_other_drug_adhd,
              onChanged: _cache[18] || (_cache[18] = ($event: any) => (_ctx.drug_anamnesis.failure_other_drug_adhd = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.sev_side_eff_mph'),
              value: _ctx.drug_anamnesis.sev_side_eff_mph,
              key: _ctx.drug_anamnesis.sev_side_eff_mph,
              onChanged: _cache[19] || (_cache[19] = ($event: any) => (_ctx.drug_anamnesis.sev_side_eff_mph = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.sev_side_eff_amf'),
              value: _ctx.drug_anamnesis.sev_side_eff_amf,
              key: _ctx.drug_anamnesis.sev_side_eff_amf,
              onChanged: _cache[20] || (_cache[20] = ($event: any) => (_ctx.drug_anamnesis.sev_side_eff_amf = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.sev_side_eff_atx'),
              value: _ctx.drug_anamnesis.sev_side_eff_atx,
              key: _ctx.drug_anamnesis.sev_side_eff_atx,
              onChanged: _cache[21] || (_cache[21] = ($event: any) => (_ctx.drug_anamnesis.sev_side_eff_atx = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.sev_side_eff_alfa'),
              value: _ctx.drug_anamnesis.sev_side_eff_alfa,
              key: _ctx.drug_anamnesis.sev_side_eff_alfa,
              onChanged: _cache[22] || (_cache[22] = ($event: any) => (_ctx.drug_anamnesis.sev_side_eff_alfa = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: 
                _ctx.$t('PharmacologicalAnamnesis.sev_side_eff_other_drug_adhd')
              ,
              value: _ctx.drug_anamnesis.sev_side_eff_other_drug_adhd,
              key: _ctx.drug_anamnesis.sev_side_eff_other_drug_adhd,
              onChanged: _cache[23] || (_cache[23] = ($event: any) => (_ctx.drug_anamnesis.sev_side_eff_other_drug_adhd = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.current_antidepressants'),
              value: _ctx.drug_anamnesis.current_antidepressants,
              key: _ctx.drug_anamnesis.current_antidepressants,
              onChanged: _cache[24] || (_cache[24] = ($event: any) => (_ctx.drug_anamnesis.current_antidepressants = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.start_antidepressants'),
              value: _ctx.drug_anamnesis.start_antidepressants,
              key: _ctx.drug_anamnesis.start_antidepressants,
              onChanged: _cache[25] || (_cache[25] = ($event: any) => (_ctx.drug_anamnesis.start_antidepressants = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.current_benzo'),
              value: _ctx.drug_anamnesis.current_benzo,
              key: _ctx.drug_anamnesis.current_benzo,
              onChanged: _cache[26] || (_cache[26] = ($event: any) => (_ctx.drug_anamnesis.current_benzo = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.start_benzo'),
              value: _ctx.drug_anamnesis.start_benzo,
              key: _ctx.drug_anamnesis.start_benzo,
              onChanged: _cache[27] || (_cache[27] = ($event: any) => (_ctx.drug_anamnesis.start_benzo = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.current_antipsychot'),
              value: _ctx.drug_anamnesis.current_antipsychot,
              key: _ctx.drug_anamnesis.current_antipsychot,
              onChanged: _cache[28] || (_cache[28] = ($event: any) => (_ctx.drug_anamnesis.current_antipsychot = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.start_antipsychot'),
              value: _ctx.drug_anamnesis.start_antipsychot,
              key: _ctx.drug_anamnesis.start_antipsychot,
              onChanged: _cache[29] || (_cache[29] = ($event: any) => (_ctx.drug_anamnesis.start_antipsychot = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.current_anti_tobac'),
              value: _ctx.drug_anamnesis.current_anti_tobac,
              key: _ctx.drug_anamnesis.current_anti_tobac,
              onChanged: _cache[30] || (_cache[30] = ($event: any) => (_ctx.drug_anamnesis.current_anti_tobac = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.start_anti_tobac'),
              value: _ctx.drug_anamnesis.start_anti_tobac,
              key: _ctx.drug_anamnesis.start_anti_tobac,
              onChanged: _cache[31] || (_cache[31] = ($event: any) => (_ctx.drug_anamnesis.start_anti_tobac = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.current_anti_oh'),
              value: _ctx.drug_anamnesis.current_anti_oh,
              key: _ctx.drug_anamnesis.current_anti_oh,
              onChanged: _cache[32] || (_cache[32] = ($event: any) => (_ctx.drug_anamnesis.current_anti_oh = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.start_anti_oh'),
              value: _ctx.drug_anamnesis.start_anti_oh,
              key: _ctx.drug_anamnesis.start_anti_oh,
              onChanged: _cache[33] || (_cache[33] = ($event: any) => (_ctx.drug_anamnesis.start_anti_oh = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.current_opi'),
              value: _ctx.drug_anamnesis.current_opi,
              key: _ctx.drug_anamnesis.current_opi,
              onChanged: _cache[34] || (_cache[34] = ($event: any) => (_ctx.drug_anamnesis.current_opi = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.start_opi'),
              value: _ctx.drug_anamnesis.start_opi,
              key: _ctx.drug_anamnesis.start_opi,
              onChanged: _cache[35] || (_cache[35] = ($event: any) => (_ctx.drug_anamnesis.start_opi = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.current_mood_stabilizers'),
              value: _ctx.drug_anamnesis.current_mood_stabilizers,
              key: _ctx.drug_anamnesis.current_mood_stabilizers,
              onChanged: _cache[36] || (_cache[36] = ($event: any) => (_ctx.drug_anamnesis.current_mood_stabilizers = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.start_mood_stabilizers'),
              value: _ctx.drug_anamnesis.start_mood_stabilizers,
              key: _ctx.drug_anamnesis.start_mood_stabilizers,
              onChanged: _cache[37] || (_cache[37] = ($event: any) => (_ctx.drug_anamnesis.start_mood_stabilizers = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.current_anticonvulsivants'),
              value: _ctx.drug_anamnesis.current_anticonvulsivants,
              key: _ctx.drug_anamnesis.current_anticonvulsivants,
              onChanged: _cache[38] || (_cache[38] = ($event: any) => (_ctx.drug_anamnesis.current_anticonvulsivants = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.start_anticonvulsivants'),
              value: _ctx.drug_anamnesis.start_anticonvulsivants,
              key: _ctx.drug_anamnesis.start_anticonvulsivants,
              onChanged: _cache[39] || (_cache[39] = ($event: any) => (_ctx.drug_anamnesis.start_anticonvulsivants = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.current_other_drug_cns'),
              value: _ctx.drug_anamnesis.current_other_drug_cns,
              key: _ctx.drug_anamnesis.current_other_drug_cns,
              onChanged: _cache[40] || (_cache[40] = ($event: any) => (_ctx.drug_anamnesis.current_other_drug_cns = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('PharmacologicalAnamnesis.start_other_drug_cns'),
              value: _ctx.drug_anamnesis.start_other_drug_cns,
              key: _ctx.drug_anamnesis.start_other_drug_cns,
              onChanged: _cache[41] || (_cache[41] = ($event: any) => (_ctx.drug_anamnesis.start_other_drug_cns = $event))
            }, null, 8, ["text", "value"]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("button", {
            onClick: _cache[42] || (_cache[42] = ($event: any) => (_ctx.$router.back())),
            type: "button",
            class: "bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }, _toDisplayString(_ctx.$t('PharmacologicalAnamnesis.Back')), 1),
          _createElementVNode("button", _hoisted_12, _toDisplayString(_ctx.$t('PharmacologicalAnamnesis.Save')), 1)
        ])
      ])
    ], 32)
  ]))
}